<template>
  <div class="main-content">
    <div class="main-table-wrapper main-content-wrapp" :class="{'modal-opened': visibleModal}">
      <div class="main-table-block">
        <list-heading>
          <list-title>{{ $t('organizations.organizations_list') }}</list-title>
          <a-button
              v-if="getRole === 1"
              type="primary"
              class="orange-btn"
              @click="setActiveModal(!visibleModal)">
            {{ $t('organizations.create_organization') }}
          </a-button>
        </list-heading>
        <div class="admin-layout__list-filters">
          <search
              :placeholderText="searchPlaceholder"
              @select="searchInList"></search>
          <div class="admin-layout__filters">
            <table-size-changer
                :defaultPageSize="pageSize"
                @select="setPageSize"/>
            <list-filter
                :organizations="organizationsDropdownList"
                :checkboxes="filterCheckboxes"
                @filterBy="filterList"
                filteredType="Organization"
                label="Organization"
            />
          </div>
        </div>
        <a-table
            :locale={emptyText:emptyText}
            class="main-table"
            :loading="loading"
            :columns="columns"
            rowKey="id"
            :pagination="false"
            :data-source="organizationsList"
            @change="handleTableChange"
            :scroll="{y: 'calc(100% - 40px)' }"
        >
          <template slot="name" slot-scope="text, record">
            <div class="name">{{record.name}}</div>
            <div class="email">{{record.email}}</div>
          </template>
          <template slot="status" slot-scope="text, record">
            <div class="status"
              :class="{'is-active': record.status.id === 1,
                   'is-inactive': record.status.id === 2,
                   'is-deleted': record.status.id === 3}">
              <span v-if="record.status.id === 1">{{$t('basics.status.active')}}</span>
              <span v-if="record.status.id === 2">{{$t('basics.status.inactive')}}</span>
              <span v-if="record.status.id === 3">{{$t('basics.status.deleted')}}</span>
            </div>
            <div class="created">{{ $t('organizations.created') }}: {{formatDate(record.created_at)}}</div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button
                @click="setDetailModalState(true, record.id)"
                type="primary">
                {{ $t('organizations.details') }}
            </a-button>
          </template>
        </a-table>
      </div>
      <pagination
          @change="setPageNumber"
          :defaultPageNumber="pageNumber"
          :paginationData="pagination"
      />
    </div>
    <create-organization
        @closeModal="setActiveModal"
        :active="visibleModal"
        :visibleDetail="visibleDetail"/>
    <organization-detail
        @detailModalState="setDetailModalState"
        @updateList="fetchData"
        :visibleDetail="visibleDetail"
        :organizationsList="organizationsDropdownList" />
  </div>
</template>

<script>
import _ from 'lodash';
import { formatDate } from '@/utils/helpers/dateFormat';
import ListTitle from '@/components/ListTitle/ListTitle'
import ListHeading from '@/components/ListHeading/ListHeading'
import Search from '@/components/Search/Search'
import TableSizeChanger from '@/components/TableSizeChanger/TableSizeChanger'
import ListFilter from '@/components/ListFilter/ListFilter'
import Pagination from '@/components/Pagination/Pagination'
import CreateOrganization from '@/views/admin/CreateOrganization/CreateOrganization'
import OrganizationDetail from '@/views/admin/OrganizationDetail/OrganizationDetail'
import { defaulVeriables } from '@/utils/defaulVeriables';
import { i18n } from '@/i18n';

const columns = [
  {
    title: i18n.t('organizations.organization_name'),
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    sorter: true,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: i18n.t('basics.bases'),
    dataIndex: 'bases_count',
    key: 'bases_count',
    sorter: false,
    scopedSlots: { customRender: 'bases_count' }
  },
  {
    title: i18n.t('basics.status_title'),
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    scopedSlots: { customRender: 'status' }
  },
  {
    title: i18n.t('organizations.managers_name'),
    dataIndex: 'manager',
    sorter: true,
    key: 'manager'
  },
  {
    title: i18n.t('organizations.organization_id'),
    dataIndex: 'id',
    sorter: true,
    key: 'id'
  },
  {
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  name: 'OrganizationList',
  components: {
    TableSizeChanger,
    ListTitle,
    ListHeading,
    Search,
    ListFilter,
    Pagination,
    CreateOrganization,
    OrganizationDetail
  },
  data() {
    return {
      formatDate,
      loading: false,
      searchPlaceholder: this.$t('organizations.search_placeholder'),
      endpoint: 'GetOrganizationsList',
      emptyText: this.$t('organizations.empty_text'),
      columns,
      filterCheckboxes: defaulVeriables,
      visibleModal: false,
      visibleDetail: false
    }
  },
  props: {
    placeholderText: {
      type: String,
      required: false,
      default: ''
    },
    defaultPageSize: {
      type: Number,
      required: false,
      default: null
    },
    defaultPageNumber: {
      type: Number,
      required: false,
      default: null
    },
    paginationData: {
      type: Object,
      required: false,
      default: null
    },
    dataSource: {
      type: Array,
      required: false,
      default: null
    },
    organizations: {
      type: Array,
      required: false,
      default: null
    },
    checkboxes: {
      type: Array,
      required: false,
      default: null
    },
    columnsData: {
      type: Array,
      required: false,
      default: null
    },
    emptyTableText: {
      type: String,
      required: false,
      default: i18n.t('bases.no_data')
    }
  },
  mounted() {
    this.fetchData();
    this.$store.dispatch('organizations/GetOrganizationDropdownList');
  },
  computed: {
    getRole() {
      return parseInt(this.$store.getters['getRole']);
    },
    organizationsList() {
      return this.$store.getters['organizations/getOrganizationsTableData']
    },
    pageSize() {
      return this.$store.getters['organizations/getPageSize'];
    },
    pageNumber() {
      return this.$store.getters['organizations/getPageNumber'];
    },
    pagination() {
      const { meta } = this.$store.getters['organizations/getOrganizationsList']
      return meta ? meta : {}
    },
    organizationsDropdownList() {
      return this.$store.getters['organizations/getOrganizations']
    }
  },
  methods: {
    fetchData(value) {
      this.loading = true;
      this.$store.dispatch('organizations/GetOrganizationsList', value ? value : {})
          .then(()=> { this.loading = false })
    },
    setPageSize(value) {
      this.$store.commit('organizations/SET_PAGE_SIZE', value)
      this.fetchData()
    },
    setPageNumber(value) {
      this.fetchData({page: value})
    },
    filterList(params) {
      this.$store.commit('organizations/SET_FILTERS', params);
      this.fetchData({params})
    },
    handleTableChange(pagination, filters, sorter) {
      var sortOrderName = sorter.order === 'ascend' ? 'asc' : 'desc',
          sortParams = {sortField: sorter.field, sortOrder: sortOrderName};

      sortParams = sorter.order ? sortParams : {}
      this.$store.commit('organizations/SET_SORT', sortParams)
      this.fetchData(sortParams)
    },
    searchInList: _.debounce(function (value) {
      this.$store.commit('organizations/SET_SEARCH_VALUE', value);
      if(value.trim().length >= 3) {
        this.fetchData({ search: value })
      } else if(value === '') {
        this.fetchData()
      }
    }, 700),
    setActiveModal(value) {
      this.visibleModal = value
    },
    setDetailModalState(value, itemID) {
      this.visibleDetail = value
      if (itemID) {
        this.$store.dispatch('detailOrganization/GetOrganization', itemID)
      }
    }
  }
}
</script>


