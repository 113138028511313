<template>
  <main-modal
      :active="active"
      @onClose="onClose"
      :bodyPadding="'0 60px'"
      :headerBorder="showFirstScreen || createOrganizationLink">
    <template v-slot:modalHeader>
      <a-button
          v-if="showFirstScreen || currentStep == 0"
          @click="onClose()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        {{ $t('organizations.organizations_list') }}</a-button>
      <a-button
          v-if="currentStep > 0 && currentStep < 4"
          @click="prevStep()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        {{ $t('basics.back') }}</a-button>
    </template>
    <template v-slot:modalBody>
      <div class="create-organization" v-if="showFirstScreen">
        <a-button type="primary" @click="showCreateManually">{{ $t('organizations.create_organization_manually') }}</a-button>
        <a-button type="primary" @click="showCreateByLink">{{ $t('organizations.create_organization_by_link') }}</a-button>
      </div>
      <div v-if="createOrganizationLink" class="create-organization">
        <create-organization-by-link @closeModal="onClose"/>
      </div>
      <div v-if="createOrganizationManually" class="create-organization-manually" style="height: 100%; overflow-y: scroll;" ref="formscontainer">
        <create-organization-manually @closeModal="onClose"/>
      </div>
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import CreateOrganizationByLink from '@/views/admin/CreateOrganizationByLink/CreateOrganizationByLink'
import CreateOrganizationManually from '@/views/admin/CreateOrganizationManually/CreateOrganizationManually'
import arrow from '@/assets/icons/back.svg?inline'

export default {
  name: 'CreateOrganization',
  props: ['active', 'headerBorder'],
  data(){
    return {
      createOrganizationManually: false,
      createOrganizationLink: false,
      showFirstScreen: true,
      step: null
    }
  },
  components: {
    arrow,
    MainModal,
    CreateOrganizationByLink,
    CreateOrganizationManually
  },
  computed: {
    currentStep() {
      return this.$store.getters['createOrganization/getCurrentStep']
    }
  },
  methods: {
    defaultModalState() {
      this.showFirstScreen = true
      this.createOrganizationManually = false
      this.createOrganizationLink = false
      this.$store.commit('createOrganization/SET_CURRENT_STEP', 0)
    },
    prevStep () {
      this.step = this.currentStep
      this.$store.commit('createOrganization/SET_CURRENT_STEP', this.step -= 1)
    },
    onClose() {
      this.$emit('closeModal', false)
      this.defaultModalState()
    },
    showCreateManually() {
      this.showFirstScreen = false
      this.createOrganizationManually = true
    },
    showCreateByLink() {
      this.showFirstScreen = false
      this.createOrganizationLink = true
    }
  }
}
</script>

<style lang="less">
  .create-organization {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -20px;

    button {
      margin-bottom: 40px;
    }
  }

  .not-editable-input {
    pointer-events: none;
    text-align: center;
  }
</style>


