<template>
  <a-form
      :form="form"
      @submit="handleSubmit">
    <a-form-item :label="$t('organizations.enter_organization_email')">
      <a-input
          size="large"
          type="email"
          @change="keyupInput"
          :placeholder="$t('organizations.enter_email')"
          v-decorator="[
                'email',
                {rules: [
                    {type: 'email', message: $t('messages.emailNotValid')},
                    {required: true, message: $t('messages.requiredField')}]
                }
              ]"
      >
      </a-input>
    </a-form-item>
    <a-button
        type="primary"
        html-type="submit"
        class="submit-btn"
        :loading="acceptButton"
        :disabled="acceptButton || hasErrors(form.getFieldsError()) || isEmailError">
      {{ $t('organizations.send_link') }}
    </a-button>
  </a-form>
</template>

<script>
import {emailNotValid, registerLinkSent, requiredEmail} from '@/utils/messages';
import { hasErrors } from '@/utils/helpers/checkFormErrors';

export default {
  name: 'CreateOrganizationByLink',
  data(){
    return {
      hasErrors,
      emailNotValid,
      requiredEmail,
      registerLinkSent,
      isEmailError: true,
      acceptButton: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'createOrganizationByLink' });
  },
  methods: {
    keyupInput() {
      this.isEmailError = false
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('createOrganization/SendCreateOrganizationLink', values)
              .then(() => {
                this.$info({
                  centered: true,
                  content: this.$t('messages.registerLinkSent'),
                  okText: this.$t('basics.yes'),
                  cancelText: this.$t('basics.cancel'),
                  width: 490,
                  closable: true,
                  onOk: () => {
                    this.$emit('closeModal')
                  },
                  onCancel: () => {
                    this.$emit('closeModal')
                  }
                })
              })
              .catch((error) => {
                this.form.setFields({
                  email: {
                    value: values.email,
                    errors: [new Error(error.response.data.error.errors.email[0])],
                  },
                });
              })
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.submit-btn {
  margin-top: 25px;
}
</style>

