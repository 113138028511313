<template>
  <main-modal
      :active="visibleDetail"
      :bodyPadding="'0 50px'"
      @onClose="onClose">
    <template v-slot:modalHeader>
      <a-button
          @click="onClose()"
          class="back-btn button-without-animation">
        <i class="anticon"><arrow/></i>
        {{ $t('organizations.organizations_list') }}</a-button>
    </template>
    <template v-slot:modalBody>
        <detail-organization-form
            :data="data"
            @onClose="onClose"
        />
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import arrow from '@/assets/icons/back.svg?inline'
import DetailOrganizationForm from "@/views/admin/DetailOrganizationForm/DetailOrganizationForm";

export default {
  name: 'OrganizationDetail',
  components: {
    arrow,
    MainModal,
    DetailOrganizationForm
  },
  data() {
    return {
      fetching: false,
      isTimeLimited: false,
      isAreaLimited: false,
      loginBtn: false,
    }
  },
  props: ['visibleDetail', 'data'],
  computed: {
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'login' });
  },
  methods: {
    onClose() {
      this.$store.commit('SET_EMAIL_SENT', false)
      this.$store.commit('detailOrganization/SET_ORGANIZATIONS_DETAIL', {})
      this.$emit('detailModalState', false)
      this.$emit('updateList')
    }
  }
}
</script>

<style lang="less" scoped>
.detail-info__column-title {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  margin-bottom: 35px;
}

.form-action-btn {
  margin-top: 50px;
  text-align: center;
}

.reset-password {
  margin: 40px 0;
}
</style>
